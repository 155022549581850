import React, { FunctionComponent } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const TauIcon: FunctionComponent<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 135.8 278.2" {...props}>
    <path d="M68.2 93.3c-.3 4.4-.8 4.4-1.1 0l-2.2-31.7a9.3 9.3 0 0 0-8.5-8.5l-35.8-2c-4.4-.3-4.4-.7 0-1l35.8-2a9.3 9.3 0 0 0 8.5-8.5L67.1 8c.3-4.4.8-4.4 1 0l2.2 31.7a9.3 9.3 0 0 0 8.5 8.4l35.9 2.1c4.4.3 4.4.7 0 1l-35.9 2a9.3 9.3 0 0 0-8.5 8.5z" />
    <path d="M78 60.3c3 3.3 2.7 3.6-.6.6l-3.7-3.3a9.3 9.3 0 0 0-12 0l-1.5 1.3c-3.4 2.8-3.7 2.5-.9-.9l1.4-1.5a9.3 9.3 0 0 0-.1-12L57.3 41c-3-3.3-2.7-3.6.6-.6l3.6 3.2a9.3 9.3 0 0 0 12 .1l1.6-1.3c3.3-2.9 3.7-2.5.8.8l-1.3 1.6a9.3 9.3 0 0 0 0 12zM19.2 99.4a2.5 2.5 0 0 1 3.6-1.6A140 140 0 0 0 71 109a288.2 288.2 0 0 0 50.2-2.6 2.4 2.4 0 0 1 2.9 2.6 92.4 92.4 0 0 0 2.2 19.5 79.7 79.7 0 0 0 8.1 21.3c.9 1.4.3 2-1.1 1.3 0 0-22.5-11.3-38-7.9s-14.7 18.6-14.7 18.6l.2 6a86.1 86.1 0 0 0 .6 8.8c.4 2.8 9.2 51 10.1 60.6s4.1 38.4 4.1 38.4c.2 1.6-.8 2.3-2.2 1.4 0 0-14.3-8.7-19-11.3s-18.3-9.4-18.3-9.4a4.4 4.4 0 0 1-2.3-4.3s5.3-35.2 3.4-56.2a209.9 209.9 0 0 0-8.1-40.5l-1.9-5.7a13.7 13.7 0 0 0-10.8-8.7 50.6 50.6 0 0 0-21 .2 109.8 109.8 0 0 0-13 4.3c-1.6.7-2.1 0-1.3-1.4 0 0 7-12.4 10.6-21.3s7.4-23.2 7.4-23.2z" />
  </SvgIcon>
);

export default TauIcon;
